<template>
  <div class="detail">
    <div class="edit_log" v-if="$route.query && $route.query.id" @click="logDrawerVisible=true">
      <i class="el-icon-warning-outline"></i>
      <span class="mt-1">动态信息</span>
    </div>
    <div class="content_block">
      <el-form
        :model="form"
        label-width="125px"
        :disabled="type === 'detail'"
        :rules="rules"
        label-position='right'
        ref="contactForm"
      >
        <el-row>
          <el-col :span="16">
            <el-form-item label="所属客户：" prop="customerId">
              <div style="display: flex; width: 100%">
                <customer-selector
                  style="flex: 1"
                  :name="form.customerName"
                  @change="(item) => handleChange('customer', item)"
                  :readonly="type === 'edit'"
                ></customer-selector>
                <!-- <el-button
                  v-if="type === 'add'"
                  type="primary"
                  @click="dialogFormVisible = true"
                  >新增客户</el-button
                > -->
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="联系人姓名：" prop="name">
              <el-input
                v-model="form.name"
                clearable
                maxlength="20"
                placeholder="请输入联系人姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="手机号：" prop="phone">
              <el-input
                v-model="form.phone"
                clearable
                :disabled="type === 'edit' && form.isAssociation"
                maxlength="11"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="性别：" prop="gender">
              <el-select v-model="form.gender" placeholder="请选择性别">
                <el-option
                  v-for="item in genderOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出生日期：">
              <el-date-picker
                v-model="form.birthDate"
                type="date"
                placeholder="选择日期"
                value-format="timestamp"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="部门：">
              <el-select
                v-model="form.department"
                filterable
                allow-create
                default-first-option
                clearable
                placeholder="请选择部门"
              >
                <el-option
                  v-for="item in departmentOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="职位：">
              <el-select
                v-model="form.position"
                filterable
                allow-create
                default-first-option
                clearable
                placeholder="请选择职位"
              >
                <el-option
                  v-for="item in positionOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="固话：">
              <el-input
                v-model="form.tel"
                maxlength="30"
                clearable=""
                placeholder="请输入固话"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="邮箱：" prop="email">
              <el-input
                v-model="form.email"
                maxlength="50"
                clearable
                placeholder="请输入公司邮箱"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="是否默认联系人：">
              <el-switch v-model="form.defaultFlag"> </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <content-block title="联系地址">
      <vxe-table
        border
        resizable
        show-overflow
        keep-source
        ref="xTable"
        :edit-rules="validRules"
        :data="form.contactAddress"
        :edit-config="{ trigger: 'manual', mode: 'row', autoClear: false }"
      >
        <vxe-column
          field="address"
          title="联系地址"
          :edit-render="{}"
        >
        <template #edit="slotParams">
          <div style="display: flex;">
          <area-cascader
            :provinceId.sync="slotParams.row.provinceId"
            :provinceName.sync="slotParams.row.provinceName"
            :cityId.sync="slotParams.row.cityId"
            :cityName.sync="slotParams.row.cityName"
            :areaId.sync="slotParams.row.areaId"
            :areaName.sync="slotParams.row.areaName"
          />
          <vxe-input type="text" v-model="slotParams.row.address" style="flex: 1; margin-left: 6px;"></vxe-input>
          </div>
        </template>
        <template #default="slotParams">
          <span>{{`${slotParams.row.provinceName}${slotParams.row.cityName}${slotParams.row.areaName}${slotParams.row.address}`}}</span>
        </template>
      </vxe-column>
        <vxe-column
          field="isDefault"
          title="是否默认地址"
          :edit-render="{ name: '$switch' }"
        >
          <template #default="{ row }">
            {{ row.isDefault ? '是' : '否' }}
          </template>
        </vxe-column>
        <vxe-column title="操作" width="160" v-if="type !== 'detail'">
          <template #default="{ row, $rowIndex }">
            <template v-if="$refs.xTable.isActiveByRow(row)">
              <vxe-button @click="handleActionClick(row, $rowIndex, 'save')"
                >保存</vxe-button
              >
              <vxe-button
                @click="handleActionClick(row, $rowIndex, 'cancel')"
                style="color: #ff0000"
                >取消</vxe-button
              >
            </template>
            <template v-else>
              <vxe-button @click="handleActionClick(row, $rowIndex, 'edit')"
                >编辑</vxe-button
              >
              <vxe-button @click="handleActionClick(row, $rowIndex, 'delete')"
                >删除</vxe-button
              >
            </template>
          </template>
        </vxe-column>
      </vxe-table>
      <el-row v-if="type !== 'detail'" type="flex" justify="center">
        <el-button
          type="primary"
          icon="el-icon-circle-plus-outline"
          plain
          style="margin-top: 24px"
          @click="addAddress"
          >新增联系地址</el-button
        >
      </el-row>
    </content-block>
    <BottomPanel>
      <slot>
        <el-button type="primary" plain @click="back">{{ type !== 'detail'? '取消' : '返回'}}</el-button>
        <el-button v-if="type !== 'detail'" type="primary" @click="save"
          >保存</el-button
        >
      </slot>
    </BottomPanel>
    <add-customer-dialog :dialogFormVisible.sync="dialogFormVisible" />
    <el-drawer
      custom-class="operation_drawer"
      title="动态信息"
      :visible.sync="logDrawerVisible"
      direction="rtl">
      <div class="pl-2 pt-2 pr-2 pb-2">
        <el-timeline v-if="form.dynamicInformList && form.dynamicInformList[0]">
          <el-timeline-item
            v-for="(activity, index) in form.dynamicInformList"
            :key="index"
            placement="top"
            type="primary"
            :timestamp="activity.operateTime | date-format">
              <div>
                <div>{{activity.actionDesc}}</div>
                <div>{{activity.content}}</div>
              </div>
          </el-timeline-item>
        </el-timeline>
        <el-empty v-else></el-empty>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import ContactApi from '@/api/contact';
import CustomerSelector from '../../components/customerSelector.vue';
import AddCustomerDialog from '../../components/addCustomerDialog.vue';
import AreaCascader from '@/components/common/areaCascader';

export default {
  name: 'CONTACT_DETAIL',
  components: {
    CustomerSelector,
    AddCustomerDialog,
    AreaCascader,
  },
  data () {
    const validateTel = (rule, value, callback) => {
      const regEx = /^1\d{10}$/;
      if (regEx.test(value)) {
        callback();
      } else {
        callback(new Error('请输入正确的手机号'));
      }
    };

    const checkEmail = (rule, value, cb) => {
      const regEmail = /^[\\.a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (value === '' || regEmail.test(value)) {
        return cb();
      }
      cb(new Error('请输入合法的邮箱'));
    };

    const validateAddress = ({ cellValue, rowIndex }) => {
      for (let i = 0; i < rowIndex; i++) {
        if (cellValue === this.form.contactAddress[i].address) {
          return new Error('联系地址已经存在');
        }
      }
    };

    return {
      dialogFormVisible: false,
      type: this.$route.query.type,
      form: {
        birthDate: '',
        customerId: '',
        customerName: '',
        defaultFlag: true,
        department: '',
        email: '',
        gender: null,
        name: '',
        phone: '',
        position: '',
        tel: '',
        contactAddress: [],
      },
      rules: {
        customerId: [
          { required: true, message: '请选择客户', trigger: 'change' },
        ],
        name: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' },
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validateTel, trigger: 'blur' },
        ],
        gender: [{ required: false, message: '请选择性别', trigger: 'change' }],
        email: [
          {validator: checkEmail, trigger: 'blur'},
        ],
      },
      validRules: {
        address: [
          { required: true, message: '请输入联系地址' },
          { validator: validateAddress },
        ],
      },
      genderOptions: [
        {
          label: '男',
          value: 'MAN',
        },
        {
          label: '女',
          value: 'WOMAN',
        },
      ],
      departmentOptions: [
        {
          label: '商务部',
          value: '0',
        },
        {
          label: '采购部',
          value: '1',
        },
        {
          label: '财务部',
          value: '2',
        },
        {
          label: '设备科',
          value: '3',
        },
      ],
      positionOptions: [
        {
          label: '销售总监',
          value: '0',
        },
        {
          label: '采购总监',
          value: '1',
        },
        {
          label: '财务总监',
          value: '2',
        },
        {
          label: '设备科长',
          value: '3',
        },
        {
          label: '科员',
          value: '4',
        },
      ],
      logDrawerVisible: false,
    };
  },
  methods: {
    handleChange (type, item) {
      if (type === 'customer') {
        this.form.customerId = item.id;
      }
    },
    getDetail () {
      ContactApi.getDetail(this.$route.query.id).then(({body}) => {
        this.form = {...body, contactAddress: body.contactAddress || []};
      });
    },
    handleActionClick (data, index, type) {
      const $table = this.$refs.xTable;

      if (type === 'edit') {
        $table.setActiveRow(data);
      } else if (type === 'cancel') {
        $table.clearActived().then(() => {
          $table.revertData(data);
        });
      } else if (type === 'save') {
        if (data.isDefault) {
          this.form.contactAddress.forEach((item, idx) => {
            if (idx !== index) {
              item.isDefault = false;
            }
          });
        }

        $table.clearActived();
      } else if (type === 'delete') {
        this.$confirm('确定删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.form.contactAddress.splice(index, 1);
        });
      }
    },
    addAddress () {
      this.form.contactAddress.push({
        provinceId: '',
        provinceName: '',
        cityId: '',
        cityName: '',
        areaId: '',
        areaName: '',
        address: '',
        isDefault: false,
      });

      const index = this.form.contactAddress.length - 1;

      this.handleActionClick(this.form.contactAddress[index], index, 'edit');
    },
    back (refresh = false) {
      this.$tabs.close({
        to: '/contact/list',
        refresh,
      });
    },
    async validAllEvent () {
      const $table = this.$refs.xTable;

      $table.clearActived();

      const errMap = await $table.validate(true).catch((errMap) => errMap);
      if (!errMap) {
        if (!this.form.contactAddress.length) {
          return this.$message.error('请新增联系地址');
        }

        const api
          = this.type === 'add'
            ? ContactApi.addContact
            : ContactApi.updateContact;

        if (this.type === 'edit') {
          this.form.contactAddress.forEach(item => {
            item.customerId = this.form.customerId;
          });
        }

        api(this.form).then(() => {
          const msg = this.type === 'add' ? '新增成功' : '修改成功';

          this.$message({
            message: msg,
            type: 'success',
          });

          this.back(true);
        });
      }
    },
    save () {
      this.$refs.contactForm.validate((valid) => {
        if (valid) {
          this.validAllEvent();
        }
      });
    },
  },
  created () {
    if (this.type !== 'add') {
      this.getDetail();
    }
  },
};
</script>

<style lang="scss" scoped>
.detail {
   .edit_log {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 26px;
    height: 108px;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    writing-mode: vertical-lr;
    z-index: 1000;
    cursor: pointer;
    background: #6FB8FF;
    border-radius: 8px 0px 0px 8px;
    &:hover {
      background: #0F89FF;;
    }

    &>span {
      letter-spacing: 4px;
    }
  }

  /deep/ .operation_drawer {
    min-width: 336px;
  }
  /deep/.el-form-item {
    margin-bottom: 12px !important;
  }

  .el-date-editor.el-input {
    width: 100%;
  }
}
</style>
